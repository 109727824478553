import React from "react";
import { graphql, useStaticQuery } from 'gatsby'
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardAvatar from "components/Card/CardAvatar.js";

import SocialShareButtons from "components/SocialButtons/SocialShareButtons.js";
import TagLinks from "components/Tags/TagLinks.js";
import { GetSiteMetadata } from 'hooks/GetSiteMetadata';

import { GatsbyImage } from "gatsby-plugin-image";

import sectionBlogInfoStyle from "assets/jss/material-kit-pro-react/views/blogPostSections/sectionBlogInfoStyle.js";

const useStyles = makeStyles(sectionBlogInfoStyle);

export default function SectionBlogInfo(props) {
  const { tags, slug, locale } = props;
  const classes = useStyles();

  const { siteMetadata } = GetSiteMetadata();

  const data = useStaticQuery(graphql`
    query SectionBlogInfoQuery {
      teamImage: imageSharp(resize: {originalName: {eq: "hfb-kathryn.jpg"}}) {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
  `)

  const postUrl = siteMetadata.siteUrl + locale.path + '/blog/' + slug + '/';

  return (
    <div className={classes.section}>
      <GridContainer justifyContent="center">
        <GridItem xs={12} sm={10} md={8}>
          <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
              <div className={classes.blogTags}>
                Tags:
                <TagLinks tags={tags} badgeColor="primary" badge="true"/>
              </div>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <SocialShareButtons
                shareUrl={postUrl}
                redirectUrl={siteMetadata.siteUrl + locale.path}
              />
            </GridItem>
          </GridContainer>
          <hr />
          <Card plain profile className={classes.card}>
            <GridContainer>
              <GridItem xs={12} sm={2} md={2}>
                <CardAvatar plain profile>
                  <GatsbyImage image={data.teamImage.gatsbyImageData} alt="Picture of Kathryn Gibson"/>
                </CardAvatar>
              </GridItem>
              <GridItem xs={12} sm={8} md={8}>
                <h4 className={classes.cardTitle}>Kathryn Gibson</h4>
                <p className={classes.description}>
                  Our team culture is positive and encouraging. We cheer each other on, we don’t allow ourselves to fall victim to our own excuses, rather we un-become everything we’re not, and become more of what we are
                </p>
              </GridItem>
              <GridItem xs={12} sm={2} md={2}>
                <a href="https://www.facebook.com/homefragrancebiz" target="_blank" rel="nofollow noreferrer" color="inherit">
                  <Button round className={classes.pullRight}>
                    Follow
                  </Button>
                </a>
              </GridItem>
            </GridContainer>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
