import React from "react";
import { Link } from "gatsby";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
// @material-ui/icons
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// core components

import { GatsbyImage, getSrc } from "gatsby-plugin-image"

import sectionTextStyle from "assets/jss/material-kit-pro-react/views/blogPostSections/sectionTextStyle.js";

import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

export default function SectionText(props) {
  const Bold = ({ children }) => <span className="bold">{children}</span>
  const Text = ({ children }) => <p className="align-center">{children}</p>

  const useStyles = makeStyles(sectionTextStyle);

  const classes = useStyles();
  const { pageContext } = props;

  const { body } = pageContext

  const imgClasses = classNames(
    classes.imgRaised,
    classes.imgRounded,
    classes.imgFluid
  );

  const options = {
    renderMark: {
      [MARKS.BOLD]: text => <Bold>{text}</Bold>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
      [BLOCKS.EMBEDDED_ASSET]: node => {
         const { gatsbyImageData, title, description } = node.data.target
         if (!gatsbyImageData) {
           // asset is not an image
           return null
         }
         if (description.includes("/shop/")) {
           return (
           <Link to={pageContext.locale.path + description + "/"}>
             <GatsbyImage image={gatsbyImageData} alt={title} className={imgClasses}/>
           </Link>)
         } else if (description.includes("homefragrancebiz.scentsy.ca")) {
           return (
           <a href={description} target="_blank" rel="nofollow noreferrer">
             <GatsbyImage image={gatsbyImageData} alt={title} className={imgClasses}/>
           </a>)
         } else {
           return (
           <GatsbyImage image={gatsbyImageData} alt={title} className={imgClasses}/>
           )
         }
       },
    },
  }

  var imageGalleryGridItems = [];
  var imageGalleryGridItems2 = [];
  var countGalleryItems = 0;
  var imageGalleryItem = "";
  if (pageContext.imageGallery != null) {
    for (const image of pageContext.imageGallery) {
      countGalleryItems++;
      if (image.description.includes("/shop/")) {
        imageGalleryItem = (
          <GridItem xs={12} sm={4} md={4} style={{ marginTop: "20px" }} key={image.id}>
            <Link to={pageContext.locale.path + image.description + "/"}>
              <img src={getSrc(image)} alt={image.title} className={imgClasses} />
            </Link>
          </GridItem>
        );
      } else if (image.description.includes("homefragrancebiz.scentsy.ca")) {
        imageGalleryItem = (
          <GridItem xs={12} sm={4} md={4} style={{ marginTop: "20px" }} key={image.id}>
            <a href={image.description} target="_blank" rel="nofollow noreferrer">
              <img src={getSrc(image)} alt={image.title} className={imgClasses} />
            </a>
          </GridItem>
        );
      } else {
        imageGalleryItem = (
          <GridItem xs={12} sm={4} md={4} style={{ marginTop: "20px" }} key={image.id}>
            <img src={getSrc(image)} alt={image.title} className={imgClasses} />
          </GridItem>
        );
      }
    }

    if (countGalleryItems <= 3) {
      imageGalleryGridItems.push(imageGalleryItem);
    } else {
      imageGalleryGridItems2.push(imageGalleryItem);
    }
  }

  var imageGalleryJSX = "";

  // if no image gallery, hide the extra title and excerpt
  if (countGalleryItems > 0) {
    imageGalleryJSX = (
      <>
        <GridItem xs={12} sm={8} md={8} key={pageContext.title}>
          <Typography variant="h1" component="p" className={classes.title}>
            { pageContext.title }
          </Typography>
          <p>
            { pageContext.excerpt.childMarkdownRemark.excerpt }
          </p>
        </GridItem>
        <GridItem xs={12} sm={10} md={10} className={classes.section} key="gallery">
          <GridContainer>
            { imageGalleryGridItems }
          </GridContainer>
        </GridItem>
      </>
    );
  }

  return (
    <div className={classes.section}>
      <GridContainer justifyContent="center">

        { imageGalleryJSX }

        <GridItem xs={12} sm={8} md={8} key="title">
          <Typography variant="h1" component="p" className={classes.title}>
            {pageContext.title}
          </Typography>
          {body && renderRichText(body, options)}
        </GridItem>
        <GridItem xs={12} sm={10} md={10} className={classes.section} key="gallery2">
          <GridContainer>
            { imageGalleryGridItems2 }
          </GridContainer>
        </GridItem>
      </GridContainer>
    </div>
  );
}
