import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Info from "components/Typography/Info.js";

import { useStaticQuery, graphql, Link } from "gatsby"
import { getSrc, getSrcSet } from "gatsby-plugin-image"

import TagLinks from "components/Tags/TagLinks.js";

import sectionSimilarStoriesStyle from "assets/jss/material-kit-pro-react/views/blogPostSections/sectionSimilarStoriesStyle.js";

const useStyles = makeStyles(sectionSimilarStoriesStyle);

export default function SectionSimilarStories(props) {
  const { pageContext } = props;
  const classes = useStyles();
  const data = useStaticQuery(
    graphql`
      query SimilarStoriesBlogPostsQuery {
        allContentfulBlogPost(limit: 3) {
          edges {
            node {
              title
              slug
              featuredImage {
                gatsbyImageData
                title
              }
              excerpt {
                childMarkdownRemark {
                  excerpt(pruneLength: 150)
                }
              }
              tags {
                tagName
                slug
              }
              publishedDate
            }
          }
        }
      }
    `
  )
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer>
          <GridItem md={12}>
            <h2 className={classes.title + " " + classes.textCenter}>
              Similar Stories
            </h2>
            <br />
            <GridContainer>
              { /** TODO - add function to set tag text color using enum **/ }
              {data.allContentfulBlogPost.edges.map(edge => {
                const postUrl = pageContext.locale.path + '/blog/' + edge.node.slug + '/';
                return (
                  <GridItem xs={12} sm={4} md={4} key={edge.node.slug}>
                    <Card blog>
                      <CardHeader image>
                        <Link to={postUrl}>
                          <img src={
                          //getSrc(edge.node.featuredImage)
                          getSrcSet(edge.node.featuredImage).split(' ')[0]
                          } alt={edge.node.featuredImage.title} />
                        </Link>
                        <div
                          className={classes.coloredShadow}
                          style={{
                            //backgroundImage: "url(" + getSrc(data.cardBackgroundImage) + ")",
                            opacity: "1",
                          }}
                        />
                      </CardHeader>
                      <CardBody>
                        <Info>
                          <h6><TagLinks tags={edge.node.tags} color="black" badge="false"/></h6>
                        </Info>
                        <h4 className={classes.cardTitle}>
                          <Link to={postUrl}>
                            {edge.node.title}
                          </Link>
                        </h4>
                        <p className={classes.description}>
                          {edge.node.excerpt.childMarkdownRemark.excerpt}
                          <Link to={postUrl}> Read More </Link>
                        </p>
                      </CardBody>
                    </Card>
                  </GridItem>
                )
              })}
            </GridContainer>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
