import React from "react";
import { GatsbySeo, BlogPostJsonLd } from 'gatsby-plugin-next-seo';
import { GetSiteMetadata } from 'hooks/GetSiteMetadata';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
import Typography from '@material-ui/core/Typography';
// @material-ui/icons
import FormatAlignLeft from "@material-ui/icons/FormatAlignLeft";
// core components
import HeaderTemplate from "components/Header/HeaderTemplate.js";
import HeaderScentsy from "components/Header/HeaderScentsy.js";
import Parallax from "components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
// sections for this page
import SectionText from "./Sections/SectionText.js";
import SectionBlogInfo from "./Sections/SectionBlogInfo.js";
import SectionSimilarStories from "./Sections/SectionSimilarStories.js";

import { getSrc, getSrcSet } from "gatsby-plugin-image"
import FooterTemplate from "components/Footer/FooterTemplate.js";

import blogPostPageStyle from "assets/jss/material-kit-pro-react/views/blogPostPageStyle.js";
import useIsClient from 'hooks/useIsClient';

const useStyles = makeStyles(blogPostPageStyle);

export default function BlogPostPage(props) {

  const { isClient } = useIsClient();
  if (isClient) {
    var isMobile = window.matchMedia('(max-width: 600px)').matches;
    var bannerImage;
    if (isMobile) {
      bannerImage = getSrcSet(props.pageContext.featuredImage).split(' ')[0]
    } else {
      bannerImage = getSrc(props.pageContext.featuredImage)
    }
  }

  const classes = useStyles();
  const { siteMetadata } = GetSiteMetadata();
  var path = props.location.pathname;
    if (path.includes("/usa")) {
      path = path.replace("/usa", "");
    }
  const postUrl = siteMetadata.siteUrl + props.pageContext.locale.path + path;
  const seoImages = [];
  seoImages.push(getSrc(props.pageContext.featuredImage));
  if (props.pageContext.imageGallery != null) {
    for (const image of props.pageContext.imageGallery) {
        seoImages.push(getSrc(image));
    }
  }
  return (
    <div>
      <GatsbySeo
        title={props.pageContext.title + props.pageContext.locale.appendToTitle}
        description={props.pageContext.excerpt.childMarkdownRemark.excerpt}
        canonical={postUrl}
        language={props.pageContext.locale.isoLanguageCode}
        openGraph={{
          title: props.pageContext.title + props.pageContext.locale.appendToTitle,
          url: postUrl,
          locale: props.pageContext.locale.ogLanguageCode,
          type: 'article',
          images: [
            {
              url: getSrc(props.pageContext.featuredImage),
              alt: props.pageContext.featuredImage.title
            }]
        }}
      />
      <BlogPostJsonLd
        url={postUrl}
        title={props.pageContext.title + props.pageContext.locale.appendToTitle}
        images={seoImages}
        datePublished={props.pageContext.publishedDate}
        dateModified={props.pageContext.updatedAt}
        authorName='Kathryn Gibson'
        description={props.pageContext.excerpt.childMarkdownRemark.excerpt}
      />
      <HeaderTemplate
        locale={props.pageContext.locale}
        location={props.location}
      />
      <Parallax image={bannerImage} filter="dark">
        <div className={classes.container}>
          <HeaderScentsy />
          <GridContainer justifyContent="center">
            <GridItem md={8} className={classes.textCenter}>
              <Hidden only={['sm','md','lg','xl']} implementation="js">
                <Typography variant="h5" component="h1" className={classes.title}>
                  {props.pageContext.title}
                </Typography>
              </Hidden>
              <Hidden only={['xs']} implementation="js">
                <h1 className={classes.title}>
                  {props.pageContext.title}
                </h1>
              </Hidden>
              <Typography variant="body1" component="p" className={classes.subtitle}>
                {props.pageContext.excerpt.childMarkdownRemark.excerpt}
              </Typography>
              <br />
              <Button color="primary" size="lg" round href="#post">
                <FormatAlignLeft />Read Article
              </Button>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classes.main} id="post">
        <div className={classes.container}>
          <SectionText
            pageContext={props.pageContext}
           />
          <SectionBlogInfo
            slug={props.pageContext.slug}
            tags={props.pageContext.tags}
            locale={props.pageContext.locale}
          />
        </div>
      </div>
      <SectionSimilarStories
         pageContext={props.pageContext}
       />
      <FooterTemplate
        locale={props.pageContext.locale}
      />
    </div>
  );
}
