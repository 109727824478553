import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import Button from 'components/CustomButtons/Button.js';
import IconButton from '@material-ui/core/IconButton';

// font awesome icons
import { FaFacebookSquare, FaInstagram, FaPinterestSquare } from "react-icons/fa";

import sectionBlogInfoStyle from "assets/jss/material-kit-pro-react/components/buttonStyle.js";

const useStyles = makeStyles(sectionBlogInfoStyle);

export default function SocialShareButtons(props) {
  const { shareUrl, redirectUrl, ...rest } = props;
  const classes = useStyles();
  const encodedShareUrl = encodeURI(shareUrl);
  const encodedRedirectUrl = encodeURI(redirectUrl);

  const facebookUrl = encodeURI("https://www.facebook.com/dialog/share?app_id=1811060842354280&display=popup&href=" + shareUrl + "&redirect_uri=" + redirectUrl );

  return (
    <div>
      <a href={facebookUrl}
              target="_blank" rel="nofollow noreferrer" color="inherit" >
        <Button color="facebook" round className={classes.buttons}>
          <FaFacebookSquare  /> Share
        </Button>
      </a>
    </div>
  );
}
